<template>
  <v-container fluid class="pa-0 ma-0">
    <v-alert color="red" type="error" v-if="!currentArticle">Aucun article trouvé</v-alert>
    <template v-else  >
    <v-img
        alt="Photo d'illustration"
        :src="getFullURL(currentArticle.mediaObjectImage)"
        transition="scale-transition"
        max-height="300px"
    >
      <v-row align="center" justify="center" class="fill-height">
        <h2 class="white--text shadow-1 text-center" style="text-shadow: 0 0 6px black;">{{ currentArticle.titre }}</h2>
      </v-row>
    </v-img>
    <v-row>
      <v-col align-center offset-sm="2" cols="12" sm="8">
        <v-btn :to="{name:'Actu'}" color="blue" dark class="mt-3 ml-3 mb-10"><v-icon class="mr-1">mdi-arrow-left-circle</v-icon> Actualité</v-btn>
        <v-row v-html="currentArticle.contenu" class="limit-img"/>
        <v-btn :to="{name:'Actu'}" color="blue" dark class="mt-10 ml-3 mb-10"><v-icon class="mr-1">mdi-arrow-left-circle</v-icon> Actualité</v-btn>
      </v-col>
    </v-row>
    </template>
  </v-container>
</template>

<script>
import ArticleMixin from "../../mixins/ArticleMixin";
import MediaObjectMixin from "../../mixins/MediaObjectMixin";

export default {
  name: "Article",
  mixins: [ArticleMixin, MediaObjectMixin],
  data: () => ({
  }),
  computed: {
    currentArticleIdToLoad() {
      return this.$route.params.articleId ? this.$route.params.articleId : null;
    }
  },
  created() {
    if (this.currentArticleIdToLoad) {
      this.loadArticle(this.currentArticleIdToLoad);
    }
  },
  watch:{
    currentArticleIdToLoad:function (before,after) {
      if(before!= after){
        this.loadArticle(this.currentArticleIdToLoad);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$font: "Roboto", sans-serif;
$font-titre: "Roboto Condensed", sans-serif;
$orange: #f1662c;
$bleu: #18b3e2;

main {
  .header-actu {
    height: 300px;
    width: 100%;
    background-position: cover;
    background-size: 100%;
    display: flex;

    .title {
      font-family: $font-titre;
      font-size: 28px;
      font-weight: bold;
      color: ghostwhite;
      text-transform: uppercase;
    }
  }

  h2 {
    font-family: $font-titre;
    font-weight: bold;
    color: $orange;
    font-size: 24px;
  }

  h3 {
    color: $orange;
    font-weight: bold;
  }

  .paraph2 {
    font-family: $font;

    span {
      color: $orange;
      font-weight: bold;
    }
  }

  .paraph-list {
    list-style: none;
  }
}
</style>
